import React from 'react'

const CenterBlock = ({ children }) => (
  <div className="flex items-center">
    <div className="w-full md:w-2/3 mx-auto text-center px-4 md:px-0">
      { children }
    </div>
  </div>
)

export default CenterBlock
