import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'
import CenterBlock from '../components/center-block'

const PartiesPage = () => (
  <Layout>
    <SEO
      title="Registration Confirmation | Jump Around Gymnastics"
      keywords={['jump around gymnastics', 'registration confirmation']}
      description={`Registration confirmation.`}
      noindex
    />
    <Header>
      <div className="w-full py-16 px-2 text-center text-white text-shadow">
        <h1 className="text-5xl text-display uppercase">Thank You</h1>
      </div>
    </Header>
    <Container>
      <div className="my-16 ">
        <CenterBlock>
          <h1 className="font-light mb-4">Registration Complete</h1>
          <p className="text-grey-darker">
            If you have any questions or concerns about your registration,
            please contact us at &nbsp;
            <OutboundLink
              className="font-bold underline-dotted hover:underline-dotted text-orange"
              href="tel:1-608-846-5867"
            >
              (608) 846&#45;5867
            </OutboundLink>
            .<br />
            We look forward to seeing you soon!
          </p>
        </CenterBlock>
      </div>
    </Container>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default PartiesPage
