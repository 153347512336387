import React from 'react'

const Header = ({ children }) => (
  <header className="-mt-12 bg-gradient-purple">
    <div className="container mx-auto">
      {children}
    </div>
  </header>
)

export default Header
